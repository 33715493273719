<template>
  <static-template>
    <h1>{{ $t("notHeader") }}</h1>
    <br /><br />
    <ul>
      <li>
        <strong>{{ $t("operatingCompany") }}:</strong> {{ $t("ocName") }}
      </li>
      <li>
        <strong>{{ $t("director") }}:</strong> {{ $t("dirName") }}
      </li>
      <li>
        <strong>{{ $t("address") }}:</strong> {{ $t("addressText") }}
      </li>
      <li>
        <strong>{{ $t("telephone") }}:</strong> {{ $t("noaPhone") }}
      </li>
      <li>
        <strong>{{ $t("emailAddress") }}:</strong> {{ $t("noaEmail") }}
      </li>
      <li>
        <strong>{{ $t("businessHours") }}:</strong> {{ $t("bizHoursText") }}
      </li>
      <li>
        <strong>{{ $t("howToOrder") }}:</strong> {{ $t("internet") }}
      </li>
      <li>
        <strong>{{ $t("aboutSalePrice") }}:</strong>
        {{ $t("aboutSalePriceText") }}
      </li>
      <li>
        <strong>{{ $t("necessaryExpenses") }}</strong>
        {{ $t("necessaryExpensesText") }}
      </li>
      <li>
        <strong>{{ $t("notPaymentMethod") }}:</strong>
        {{ $t("notPaymentMethodText") }}
      </li>
      <li>
        <strong>{{ $t("paymentTime") }}:</strong>
        {{ $t("paymentTimeText") }}
      </li>
      <li>
        <strong>{{ $t("productAvailability") }}:</strong>
        {{ $t("productAvailabilityText") }}
      </li>
      <li>
        <strong>{{ $t("returnCancellation") }}:</strong>
        {{ $t("returnCancellationText") }}
      </li>
    </ul>
  </static-template>
</template>

<script>
export default {
  name: "NotationPage",
};
</script>
